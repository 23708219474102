import React, { useContext, useState, useEffect } from 'react';
import { Close } from 'grommet-icons';
import { Tag, Pagination, Carousel, ResponsiveContext, Box, Button, Text, Layer, Spinner, Tip } from 'grommet';

import teamCanaryServices from '../../../Services/teamCanaryServices';
import { aws_regions } from '../../../Store/url';

const DialogBoxGUI = (props) => {
    // console.log("In the Dialog Box GUI")
    const [loadData, setLoadData] = useState(true);
    const [pagesData, setPagesData] = useState([]);
    const [currentData, setCurrentData] = useState();

    const size = useContext(ResponsiveContext);
    // console.log(props)

    const date = new Date(props.data.timestamp);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);


    useEffect(() => {
        async function fetchData(s3Locations) {
            try {
                let tempPagesData = []

                for (let index in s3Locations) {
                    console.log(s3Locations[index].artifactLocation)
                    var result = await teamCanaryServices.getArtifacts(s3Locations[index].artifactLocation);
                    result  = JSON.parse(result.body)
                    let images = [], harfile = "", logfile = "";
                    let png = "png", log = "txt", har = "har";
                    for (let urlIndex in result.message) {

                        if (result.message[urlIndex].includes(png)) {
                            images.push(result.message[urlIndex])
                        }
                        if (result.message[urlIndex].includes(log)) {
                            logfile = result.message[urlIndex]
                        }
                        if (result.message[urlIndex].includes(har)) {
                            harfile = result.message[urlIndex]
                        }

                    }

                    let dataCreated = {
                        'images': images,
                        'harFile': harfile,
                        'logFile': logfile,
                        "status": s3Locations[index].status
                    }
                    tempPagesData.push(dataCreated)
                }
                // console.log(tempPagesData)
                setPagesData(tempPagesData);
                setCurrentData(tempPagesData.slice(0, 1))
                setLoadData(false)
                console.log(pagesData)
            } catch (error) {
                console.error(error);
            }
        }
        fetchData(props.data.artifactsStatus.artifacts);
    }, []);

    const handleChange = ({ startIndex, endIndex }) => {
        const nextData = pagesData.slice(startIndex, endIndex);
        setCurrentData(nextData);
    };

    const downloadFile = (url, filename) => {
        const fileUrl = url;

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = filename;

        // Programmatically trigger the click event
        link.click();
    }


    return (
        <>{
            <Layer
                id="hello world"
                position="center"
            >
                <Box pad="small" size="large">
                    <Button alignSelf='end' icon={<Close />} onClick={() => props.closeDialogBox()} primary />
                </Box>
                <Box pad="small" gap="small" width="large">
                    <Box pad="small" gap="small" align="center" direction='row' justify='between'>
                        <Tag name="Service" value={props.data.team} />
                        <Tag name="Type" value="GUI" />
                        {
                            !loadData &&
                            <Button alignSelf='center'  >
                                <Text alignSelf='center' size='large' weight='bolder' style={{ backgroundColor: currentData[0].status ? "#00D100" : "#FF0000", color: "whitesmoke", borderRadius: "10px" }} >&nbsp;{currentData[0].status ? "Passed" : "Failed"}&nbsp;</Text>
                            </Button>
                        }

                    </Box>

                    <Box gap="small" direction="row" justify="between" align="center" pad="small">
                        <Box>
                            <Text weight="bold" size="medium">Canary:</Text>
                            <Text size="medium">{props.data.canary}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Cluster:</Text>
                            <Text size="medium" >{props.data.cluster}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Region:</Text>
                            <Text size="medium">{aws_regions[props.data.region]}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Timestamp(UTC) :</Text>
                            <Text size="medium">{formattedDate}</Text>
                        </Box>

                    </Box>

                    <>
                        {
                            loadData &&
                            <Box alignSelf="center" direction="row" gap="small" pad="small">
                                <Spinner /><Text>Loading...</Text>
                            </Box>
                        }
                        {
                            !loadData &&
                            <>
                                {
                                    currentData[0].images.length === 0 &&
                                    <Text alignSelf='center'>No screenshots found</Text>
                                }
                                {
                                    currentData[0].images.length !== 0 &&
                                    <Carousel height="medium" width="large">
                                        {currentData[0].images.map((imageUrl, index) => (
                                            <Box key={index} height="auto">
                                                <img src={imageUrl} alt={`Image ${index + 1}`} />
                                            </Box>
                                        ))}
                                    </Carousel>
                                }


                                <Box
                                    as="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: 'small', bottom: 'small' }}
                                >

                                    {
                                        currentData[0].logFile === "" &&
                                        <Tip content={<Text>Log file not found</Text>}>

                                            <Button
                                                label={
                                                    <Text color="white">
                                                        <strong>Download logs</strong>
                                                    </Text>
                                                }
                                                primary
                                                color="grey"
                                            />
                                        </Tip>
                                    }
                                    {
                                        currentData[0].logFile !== "" &&
                                        <Button
                                            label={
                                                <Text color="white" onClick={downloadFile.bind(this, currentData[0].logFile, "log.txt")}>
                                                    <strong>Download logs</strong>
                                                </Text>
                                            }
                                            primary
                                            color="green"
                                        />
                                    }



                                    {
                                        currentData[0].harFile === "" &&
                                        <Tip content={<Text>har file not found</Text>}>
                                            <Button
                                                label={
                                                    <Text color="white">
                                                        <strong>Har file</strong>
                                                    </Text>
                                                }
                                                primary
                                                active={false}
                                                color="grey"
                                            />
                                        </Tip>
                                    }
                                    {
                                        currentData[0].harFile !== "" &&
                                        <Button
                                            label={
                                                <Text color="white" onClick={downloadFile.bind(this, currentData[0].harFile, "har.html")}>
                                                    <strong>Har file</strong>
                                                </Text>
                                            }
                                            primary
                                            color="green"
                                        />
                                    }




                                </Box>
                                <Box align="center" pad="small" direction='row' alignSelf='center'>
                                    <Text weight="bold" size="medium">Pages</Text>
                                    <Pagination align='center' numberItems={2} step={1} onChange={handleChange} />
                                </Box>
                            </>
                        }



                    </>
                </Box>
            </Layer>
        }
        </>
    );
};
// DialogBox.storyName = 'Center';
// DialogBox.parameters = {
//     chromatic: { disable: false },
// };
// DialogBox.args = {
//     full: false,
//     options: {
//         layer: {
//             singleId: true,
//         },
//     },
// };
export default DialogBoxGUI;   