import React, { useState } from 'react';
import { BrowserRouter as Router, Route, RouterProvider,createBrowserRouter } from 'react-router-dom';
import Login from './components/LoginComponent/loginComponent';
import Dashboard from './components/DashboardComponent/dashboard';
import { tokenLoader,checkAuthLoader,checkAuthLoaderLogin } from './utils/auth';
import ErrorPage from './components/ErrorPage/error';
const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    id: 'root',
    loader: tokenLoader,
    children: [
      { 
        index: true, 
        element: <Dashboard /> ,
        loader: checkAuthLoader,
      },
      {
        path: 'login',
        element: <Login />,
        loader:checkAuthLoaderLogin
      },
      // {
      //   path: 'logout',
      //   action: logoutAction,
      // },
    ],
  },
]);
  
function App() {
  return <RouterProvider router={router} />;
}
  
export default App;