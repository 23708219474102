import React, { useState, useContext } from 'react';
import {
    Card,
    Anchor,
    Box,
    Button,
    CheckBox,
    Form,
    FormField,
    Header,
    Heading,
    Layer,
    ResponsiveContext,
    Text,
    TextInput,
    Grommet
} from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import MainHeader from '../headerComponent/header';

const ErrorPage = () => {

    const size = useContext(ResponsiveContext);
 
    return (
        <Grommet full theme={hpe}>

            <Box >
                <MainHeader />
            </Box>

            <Box  align='center' pad="large" >
                <Header
                    direction="column"
                    align="start"
                    gap="xxsmall"
                    pad={{ horizontal: 'xxsmall' }}
                >
                    <Heading level={1} margin="none">
                        Error !!!!
                    </Heading>
                    <Text>You have landed on wrong page :)</Text>
                </Header>
                <Box
                    pad={{ horizontal: 'xxsmall' }}
                >
                </Box>
            </Box>
            
        </Grommet>

    );
};

export default ErrorPage;






