import React, { useContext, useState, useEffect } from 'react';
import { Close, CaretRightFill, CaretDownFill } from 'grommet-icons';
import { Tag, Grid, Pagination, InfiniteScroll, ResponsiveContext, Box, Button, Text, Layer, Spinner, Card } from 'grommet';

import teamCanaryServices from '../../../Services/teamCanaryServices';
import { aws_regions } from '../../../Store/url';


const CardResult = (data) => {

    const [expandData, setExpandData] = useState(!data.expand);
    
    useEffect(() => {
        setExpandData(expandData => expandData = !data.expand)
    }, [data.expand]);

    const date = new Date(data.item.timestamp);
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const jsonData = {
        timestamp: data.item.timestamp,
        message: data.item.message,
    };

    let hightlightColor = undefined
    if (data.item.message.indexOf("error") !== -1) {
        hightlightColor = "red"
    }

    const ChangeExpandFunction = () => {
        setExpandData(expandData => !expandData)
    }


    return (
        <>
            <Card
                pad="xsmall"
                gap='2%'
            >
                <Box direction='row' gap="xsmall" >
                    <Box alignSelf='center' onClick={ChangeExpandFunction}>
                        {expandData && <CaretRightFill size='small' />}
                        {!expandData && <CaretDownFill size='small' />}
                    </Box>
                    <Text style={{ colors: "white", backgroundColor: hightlightColor === undefined ? "white" : "#FFCCCB" }} pad="small" weight="bold" >{formattedDate}</Text>
                </Box>
                <Box pad="xsmall" backgroundColor="white">
                    <Text truncate={expandData}>{data.item.message}</Text>
                </Box>
            </Card>
        </>
    )
}

const DialogBoxAPI = (props) => {
    // console.log("In the Dialog Box API")
    const [loadData, setLoadData] = useState(true);
    const [pagesData, setPagesData] = useState([]);
   
    const [globalExpandData, setGlobalExpandData] = useState(false);


    const size = useContext(ResponsiveContext);
    // console.log(props)

    const date = new Date(props.data.timestamp);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);


    useEffect(() => {
        async function fetchData() {
            let artifacts = props.data.artifactsStatus.artifacts
            let region = props.data.region
            if (region !== '' && artifacts.log_group !== '' && artifacts.log_stream != '') {
                console.log("We are calling Cloudwatch api here",region , artifacts.log_group, artifacts.log_stream)
                var result = await teamCanaryServices.getCloudwatchLogs(region, artifacts.log_group, artifacts.log_stream);
                result = JSON.parse(result.body)
                
                for (let index = 0; index < result.length; index++) {
                    // console.log(result[index]);
                    result[index]["pos"] = index
                }
                console.log(result)
                setPagesData(result)
                setLoadData(false)
            }
            else {
                setLoadData(false)
            }

        }
        fetchData();
    }, []);


    //     const nextData = pagesData.slice(startIndex, endIndex);
    //     setCurrentData(nextData);
    // };

    const ChangeExpandFunction = () => {
        setGlobalExpandData(expandData => !expandData)
        console.log("clciked on global expand button", globalExpandData)
    }

    
    return (
        <>{
            <Layer
                id="hello world"
                position="center"
            >
                <Box pad="small" size="large">
                    <Button alignSelf='end' icon={<Close />} onClick={() => props.closeDialogBox()} primary />
                </Box>
                <Box pad="small" gap="small" width="large">
                    <Box pad="small" gap="small" align="center" direction='row' justify='between'>
                        <Tag name="Service" value={props.data.team} />
                        <Tag name="Type" value="API" />
                        {/* {
                            !loadData &&
                            <Button alignSelf='center'  >
                                <Text alignSelf='center' size='large' weight='bolder' style={{ backgroundColor: currentData[0].status ? "#00D100" : "#FF0000", color: "whitesmoke", borderRadius: "10px" }} >&nbsp;{currentData[0].status ? "Passed" : "Failed"}&nbsp;</Text>
                            </Button>
                        } */}

                    </Box>

                    <Box gap="small" direction="row" justify="between" align="center" pad="medium">
                        <Box>
                            <Text weight="bold" size="medium">Canary:</Text>
                            <Text size="medium">{props.data.canary}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Cluster:</Text>
                            <Text size="medium" >{props.data.cluster}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Region:</Text>
                            <Text size="medium">{aws_regions[props.data.region]}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Timestamp(UTC) :</Text>
                            <Text size="medium">{formattedDate}</Text>
                        </Box>

                    </Box>

                    <>
                        <Card direction='row' pad="small" margin="small" gap="small" style={{ minHeight: "55px" }} >
                            <Box alignSelf='center' onClick={ChangeExpandFunction}>
                                {globalExpandData && <  CaretDownFill size='small' />}
                                {!globalExpandData && <CaretRightFill size='small' />}
                            </Box>
                            <Text pad="small" weight="bold" >Timestamp : Messages</Text>
                        </Card>

                        {
                            loadData &&
                            <Box alignSelf="center" direction="row" gap="small" pad="small">
                                <Spinner />
                                <Text>Loading...</Text>
                            </Box>
                        }
                        {
                            !loadData &&
                            <>
                                <Box overflow={{ vertical: "auto" }} height="80%" pad="small" background="white" elevation="true" round="small">
                                    <Grid gap="medium" justify="evenly">

                                        {/* {pagesData.map((data) => (
                                            <CardResult item={data} key={data.message} expand={globalExpandData} />
                                        ))} */}

                                        { pagesData.length !== 0 && 
                                            <InfiniteScroll items={pagesData} step={10}>
                                                {((data) => {
                                                return <CardResult item={data} key={data.pos} expand={globalExpandData}/>
                                                })}
                                            </InfiniteScroll>
                                        }{pagesData.length === 0 && 
                                            <h3 align="center">No Logs found</h3>
                                        }

                                        
                                    </Grid>
                                </Box>
                            </>
                        }



                    </>
                </Box>
            </Layer>
        }
        </>
    );
};
// DialogBox.storyName = 'Center';
// DialogBox.parameters = {
//     chromatic: { disable: false },
// };
// DialogBox.args = {
//     full: false,
//     options: {
//         layer: {
//             singleId: true,
//         },
//     },
// };
export default DialogBoxAPI;


// "artifacts":{
//     "log_group":"",
//     "log_stream":""
// }
