import React, { useContext,useState } from 'react';
import { Button, Box, Header, Heading, CheckBox, ResponsiveContext } from 'grommet';
import { Hpe } from 'grommet-icons';

const items = [
  { label: ' Executive View' },
  { label: ' Team View' }
];

/**
 * MyComponent - This Component deal with HPE logo and status bar.
*/
const MainHeader = () => {
  const size = useContext(ResponsiveContext);
  const [checkBox, setCheckBox] = useState(true);

  return (

    <Header fill="horizontal">

      <Button>

        {/* This took care about HPE logo */}
        <Box
          direction="row"
          align="start"
          gap="medium"
          pad={{ vertical: 'small' }}
          responsive={false}
        >
          {(!['xsmall', 'small'].includes(size) ||
            (['xsmall', 'small'].includes(size))) && (
              <Box direction="row" gap="xsmall" wrap pad="xxsmall">
                <Hpe color="brand" size='65%' pad="large" />
                <Heading level={2} color="text-strong" weight="bold">
                  HPE
                </Heading>
                <Heading level={2} >
                  Continous Monitoring
                </Heading>
              </Box>
            )}

        </Box>

      </Button>

      {/* It handles the toggle feature in Engineering view and Executive view*/}
      {/* <CheckBox
        name="toggle"
        toggle
        checked={checkBox}
        label="Team View"
        onChange={(event) => setCheckBox(event.target.checked)}
      /> */}

    </Header>
  );
};
export default MainHeader;