import React, { useContext, useState } from 'react';
import { Close } from 'grommet-icons';
import { Tag, ResponsiveContext, Box, Button, Text, Layer, Spinner, CheckBox, Select, RangeSelector, Card } from 'grommet';

import { LineChart, Line, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import HistoricalService from '../../../Services/HistoricalService';
import { aws_regions } from '../../../Store/url';

const DialogBoxPhase2 = (props) => {


    // console.log(props)

    const [loadData, setLoadData] = useState(false);
    const [historicalData, setHistoricalData] = useState([])
    const [historicalViewcheckBox, sethistoricalViewcheckBox] = useState(false);
    const [downloadlogs, setDownloadlogs] = useState({});

    console.log(downloadlogs)
    const timeoptions = ['1 day', '2 day', '3 day', '4 day', '5 day', '6 day', '1 week', '2 week', '3 week', '4 week'];
    const timeoptionsInNumeric = { '1 day': 1, '2 day': 2, '3 day': 3, '4 day': 4, '5 day': 5, '6 day': 6, '1 week': 7, '2 week': 14, '3 week': 21, '4 week': 28 };

    const [selectTimeRangevalue, setSelectTimeRangevalue] = useState('1 week');


    const size = useContext(ResponsiveContext);

    // here we are trying to convert the UTC time in IST and PST timestandard.
    const date = new Date(props.data.timestamp)
    const originalDate = new Date(date.toString());
    const hoursToAddIST = 5;
    const minutesToAddIST = 30;
    const hoursToAddPST = 7;
    // Calculate the new date by adding hours and minutes
    const newDateIST = new Date(originalDate.getTime() + (hoursToAddIST * 3600000) + (minutesToAddIST * 60000));
    const newDatePST = new Date(originalDate.getTime() + (hoursToAddPST * 3600000));

    // Format the new date
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };
    const istDate = newDateIST.toLocaleString('en-US', options);
    const pstDate = newDatePST.toLocaleString('en-US', options);

    // Helps to download the file
    const downloadFile = (url, filename) => {
        const fileUrl = url;

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = filename;

        // Programmatically trigger the click event
        link.click();
    }

    const setHistoricalDataWithTimerange = (option) => {
        setSelectTimeRangevalue(option)
        setLoadData(true)
        fetchHistoricalData(option)
    }


    const setHistoricalView = (event) => {
        sethistoricalViewcheckBox(event.target.checked)
        if (event.target.checked === true) {
            setLoadData(true)
            fetchHistoricalData(selectTimeRangevalue)
        }
    }


    const fetchHistoricalData = async (selectedTime) => {

        console.log(selectedTime, timeoptionsInNumeric[selectedTime])
        console.log("+++++++++++++++++++++++++++++++++")
        const currentDate = new Date();
        const endTimestamp = (currentDate.getTime());
        const startTimestamp = (currentDate.getTime() - timeoptionsInNumeric[selectedTime] * 24 * 60 * 60 * 1000)
        console.log(startTimestamp, endTimestamp);

        const queryData = {
            "query": true,
            "phase1": false,
            "phase2": true,
            "team": props.data.team,
            "canary": props.data.canary,
            "cluster": props.data.cluster.toLowerCase(),
            "region": props.data.region.toLowerCase(),
            "timestampStart": startTimestamp,
            "timestampEnd": endTimestamp
        }

        // we are fetching the data from historicalservices ap
        const result = await HistoricalService.getHistoricalInfo(queryData)
        console.log(result)
        const data = [];
        for (let i = 0; i < result.length; i++) {
            const info = result[i].split(" ");
            const runTime = info[1].split("-")
            // we are removing mili second from time taking only hh:mm:ss
            runTime[0] = runTime[0].substring(0, 8);
            data.push({
                "x": info[0] + " " + runTime[0],
                "y": runTime[1],
                'log': runTime[2] + '-' + runTime[3]
            });
        }

        console.log(data)
        setHistoricalData(data)
        setLoadData(false)
    }


    const customTickOrder = ["Failed", "Passed"]

    const handleDataPointClick = (dataPoint, index, event) => {
        // Handle the click event on the tooltip
        console.log('Tooltip clicked:', dataPoint);
    };

    // we are trying to beautify the dot
    const CustomizedDot = (props) => {
        const { cx, cy } = props;
        return (
            <svg x={cx - 5} y={cy - 5} width={10} height={10}>
                <circle cx={5} cy={5} r={5} fill={props.payload.y === "Passed" ? "green" : "red"} />
            </svg>
        );
    };

    //  trying to customize the y axis
    const CustomizedYAxisTick = ({ x, y, payload }) => (
        <>
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill={payload.value === "Passed" ? "green" : "red"} stroke="2">
                    {payload.value === "Passed" ? "100%" : "0%"}
                </text>
            </g>
        </>
    );


    // we are trying to customize tooltip
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <Card pad="small" gap='small'>
                    <Text>{`Time: ${label} UTC`}</Text>
                    <Text>{`Status: ${payload[0].value}`} </Text>
                    {/* <Button  onSubmit={handleTooltipClick(payload[0].payload)}>{`${payload[0].payload.log}`}</Button> */}
                </Card>
            );
        }
        return null;
    };

    const formatTime = (time) => {
        const date = new Date(time);
        const day = date.getDate(); // Get the day (1-31)
        const month = date.getMonth(); // Get the month (0-11, where 0 is January)
        // const year = date.getFullYear();
        return `${day}/${month + 1}`;
    };


    return (
        <>{
            <Layer
                position="center"
            >
                <Box pad="small" size="large" margin="xsmall" direction='row' justify='between'>
                    <CheckBox
                        name="toggle"
                        toggle
                        checked={historicalViewcheckBox}
                        label="Historical View"
                        onChange={(event) => setHistoricalView(event)}
                    />
                    <Button alignSelf='end' icon={<Close />} onClick={() => props.closeDialogBox()} primary />
                </Box>


                <Box pad="small" gap="small" width="large">
                    <Box pad="small" gap="small" align="center" direction='row' justify='between'>
                        <Tag name="Service" value={props.data.team} />
                        <Tag name="Type" value="GUI" />
                    </Box>

                    <Box gap="small" direction="row" justify="between" align="center" pad="small">
                        <Box>
                            <Text weight="bold" size="medium">Canary:</Text>
                            <Text size="medium">{props.data.canary}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Cluster:</Text>
                            <Text size="medium" >{props.data.cluster}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Region:</Text>
                            <Text size="medium">{aws_regions[props.data.region]}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Timestamp(IST) :</Text>
                            <Text size="medium">{istDate}</Text>
                        </Box>
                        <Box>
                            <Text weight="bold" size="medium">Timestamp(PST) :</Text>
                            <Text size="medium">{pstDate}</Text>
                        </Box>
                    </Box>

                    <>
                        {/* Here we are showcasing default spinner loader */}
                        {
                            loadData &&
                            <Box alignSelf="center" direction="row" gap="small" pad="small">
                                <Spinner /><Text>Loading Historical Data ...</Text>
                            </Box>
                        }

                        {
                            historicalViewcheckBox && !loadData &&
                            <Box fill align="end" pad="small" >
                                <Select
                                    id="select"
                                    name="select"
                                    placeholder="Time Range"
                                    value={selectTimeRangevalue}
                                    options={timeoptions}
                                    onChange={({ option }) => setHistoricalDataWithTimerange(option)}
                                />
                            </Box>
                        }

                        {/* Here we are showcasing the historical graph */}
                        {
                            historicalViewcheckBox && !loadData &&
                            <div style={{ width: "100%", height: 300 }}>
                                <ResponsiveContainer width="100%" >
                                    <LineChart
                                        data={historicalData}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 10,
                                        }}
                                        Line={true}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            type="category"
                                            domain={['auto', 'auto']}
                                            dataKey="x"
                                            tickCount={10}
                                            tickFormatter={formatTime}
                                        />
                                        <YAxis
                                            type="category"
                                            dataKey="y"
                                            tick={<CustomizedYAxisTick />} // Customize the tick component
                                            domain={customTickOrder}
                                        />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend />
                                        <Line
                                            onClick={handleDataPointClick}
                                            stroke="none"
                                            dataKey="y"
                                            legendType="circle"
                                            dot={<CustomizedDot />}
                                            name='Historical graph'

                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>

                        }

                    </>
                </Box>
            </Layer>
        }
        </>
    );
};

export default DialogBoxPhase2;