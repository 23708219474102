import { Grid, Box } from 'grommet';
import { title, Places } from './WorldMapComponent/worldMap';
import Ticker from './Ticker/ticker';
import GlobalStatus from './GlobalStatus/globalStatus';

const middleBody = () => {

    const coloumns = ['2/3', '1/4'];
    const padding = { 'left': 'small' }
    return (
        <Grid
            columns={coloumns}
            gap="5%"
            pad={padding}
            style={{maxHeight:"90%"}}
        >
            <Places />
            <Grid gap="xsmall">
                <GlobalStatus></GlobalStatus>
                <Ticker></Ticker>
            </Grid>
        </Grid>
    )
}

export default middleBody;