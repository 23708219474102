import React, { useState, useContext } from 'react';
import {
    Card,
    Anchor,
    Box,
    Button,
    CheckBox,
    Form,
    FormField,
    Header,
    Heading,
    Layer,
    ResponsiveContext,
    Text,
    TextInput,
    Grommet
} from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import {  Next, CircleAlert } from 'grommet-icons';

import { setAuthToken } from '../../utils/auth';
import MainHeader from '../headerComponent/header';
import AuthenticateService from '../../Services/AuthenticateService'
import { useNavigate } from "react-router-dom";


const VerifyPassword =async  (key,navigate) =>{
    
    const result  = await AuthenticateService.getAuthenticate(key)
    console.log(result["body"])
    const resultStatus = JSON.parse(result["body"])
    console.log(resultStatus)
    // verify if the password is true
    if (resultStatus["status"]=="Success") {
        console.log(result)
        const authToken = resultStatus["token"];
       // Update state in parent component
       setAuthToken(authToken);
       navigate('/')
       return true
   } else {
       return false
   }

}

const Login = () => {

    const size = useContext(ResponsiveContext);
    const navigate = useNavigate();
    const [accessKey, setAccessKey] = useState('');
    const [credentialError, setCredentialError] = React.useState(false);
    const [formValues, setFormValues] = React.useState({
        accessKey: '',
    });

    const handleLogin = () => {
        console.log(formValues.accessKey)

        const ans  =  VerifyPassword(formValues.accessKey,navigate)
    };

    return (
        <Grommet full theme={hpe}>

            <Box >
                <MainHeader />
            </Box>

            <Box  align='center' pad="large" >
                <Header
                    direction="column"
                    align="start"
                    gap="xxsmall"
                    pad={{ horizontal: 'xxsmall' }}
                >
                    <Heading level={2} margin="none">
                        Sign In
                    </Heading>
                    <Text>to Continous Monitoring Dashboard</Text>
                </Header>
                <Box
                    pad={{ horizontal: 'xxsmall' }}
                >

                    <Form
                        validate="blur"
                        value={formValues}
                        onChange={setFormValues}
                        messages={{
                            required: 'This is a required field.',
                        }}
                        onSubmit={handleLogin}
                    >

                        {/* Field to type the password */}
                        <FormField
                            label="Access Key"
                            htmlFor="password-sign-in"
                            name="accessKey"
                            required={{ indicator: false }}
                        >
                            <TextInput
                                id="password-sign-in"
                                name="accessKey"
                                placeholder="Enter your access key"
                                type="password"
                            />
                        </FormField>

                        {/* if password wrong then a pop message occur */}
                        {credentialError && (
                            <Box
                                animation="fadeIn"
                                align="center"
                                background="validation-critical"
                                direction="row"
                                gap="xsmall"
                                margin={{ top: 'medium', bottom: 'medium' }}
                                pad="small"
                                round="4px"
                            >
                                <CircleAlert size="small" />
                                <Text size="xsmall">Invalid credentials.</Text>
                            </Box>
                        )}

                        {/* When we click on signin the form got accepted */}
                        <Box
                            align={!['xsmall', 'small'].includes(size) ? 'start' : undefined}
                            margin={{ top: 'medium', bottom: 'small' }}
                        >
                            <Button
                                label="Sign In"
                                icon={<Next />}
                                reverse
                                primary
                                type="submit"
                            />
                        </Box>

                    </Form>
                </Box>
            </Box>
            
        </Grommet>

    );
};

export default Login;






