import React, { useState } from "react";
import { Box, Grid, Card, Text, Button, Stack} from 'grommet';
import { Filter, Refresh } from 'grommet-icons';
import { useDispatch } from "react-redux";

import { executiveViewUIActions } from "../../Reducers/ExecutiveViewUI/executiveViewUISlice";
import { filterStorageActions } from "../../Reducers/FilterStorage/filterStorageSlice";
import FilterSidebar from "./filterOption/Sidebar";
import TeamDetailList from "./teamDetailList";


/**
 * TeamDetailComponent - A custom function which set all data.
 * @returns {JSX.Element} The rendered component.
 */
const TeamDetail = () => {
    const dispatch = useDispatch()

    const [filterOption, setfilterOption] = useState(false);
    const [refresh, setReferesh] = useState(false)
    const [canaryType, setCanaryType] = useState({ 'API': true, 'GUI': true })
    const [phase, setPhase] = useState("Continous Monitoring")


    const onClickReferesh = () => {
        setReferesh(refresh => !refresh)
    }

    const openSidebar = () => {
        setfilterOption((filterOption) => !filterOption)
    }

    // This function called from filterOption Componenet and will set the filter options
    const selectedFilterOption = (cluster, phase, canaryType) => {
        // console.log(canaryType)
        setPhase(phase)
        setCanaryType(canaryType)
        dispatch(executiveViewUIActions.setToggleCluster(cluster))
        const filterStorage = {
            cluster: cluster,
            phase: phase,
            canaryType: canaryType
        }
        dispatch(filterStorageActions.setPreviousFilterOption(filterStorage))
    }

    return (
        <>
            {/* This is handling the filter button and its operation */}
            <div >
                {
                    filterOption &&
                    <FilterSidebar
                        filterOption={filterOption}
                        openSidebar={openSidebar}
                        selectedFilterOption={selectedFilterOption}
                    />
                }
            </div>

            <Box round="small" background="" height="large" margin={{ top: '5%', left: '7%', right: '7%' }} >
                <Grid  rows={['xxsmall', 'large']} gap="small">

                    {/* This component show the header bar and provide filter button and refersh button*/}
                    <Card background="brand" gap="medium" justify="between" direction="row">
                        <Text style={{ padding: "2%" }} size="30px" color="White" alignSelf="center" >Services</Text>
                        <Card elevation="None" pad="small" gap="small" direction="row" alignContent="center" background="None">
                            <Button alignSelf="center" icon={<Refresh />} color="white" onClick={onClickReferesh} tip="Refresh" />
                            <Button icon={<Filter />} alignSelf="center" size="large" onClick={openSidebar} tip="Filter" />
                        </Card>
                    </Card>

                    {/* Sends data to another component which display teams*/}
                    <TeamDetailList refresh={refresh} phase={phase} canaryType={canaryType}/>

                </Grid>

            </Box>

        </>

    )

}

export default TeamDetail;