import { createSlice } from "@reduxjs/toolkit";

const initialization = {
    executiveViewController: {
        cluster : "Aquila"
    },
};

const executiveViewUISlice = createSlice({
    name: "ExecutiveViewUIController",
    initialState: initialization,
    reducers: {
        setToggleCluster(State, Action) {
            State.executiveViewController.cluster = Action.payload
        }
    },
});

export const executiveViewUIActions = executiveViewUISlice.actions;
export default executiveViewUISlice;
