import axios from 'axios'
import { authenticationURL } from '../Store/url.js';
export default {
    async getAuthenticate(key) {
        const authorizationToken = process.env.REACT_APP_CONTINOUS_MONITORING_AUTHENTICATE_API_KEY
        try {
            var response = await axios.get(
                `${authenticationURL}/authentication`,
                {
                    params: {
                        "key": key
                    },
                    headers: {
                        "x-api-key": authorizationToken,
                    }
                    
                }
            );
        } catch (error) {
            console.log(error)
            return ""
        }
        return response.data;
    },

};

