import { Route, Redirect } from 'react-router-dom';
import { Grommet } from 'grommet';
import { hpe } from 'grommet-theme-hpe';

import MainHeader from '../headerComponent/header';
import MiddleBody from '../MiddleComponent/middleBody'
import TeamDetail from '../TeamDetail/teamDetail';
import { getAuthToken } from '../../utils/auth';


// function PrivateRoute({ children, ...rest }) {
//  const token = getAuthToken()
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         token ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }


function Dashboard() {
  
    return (
      <Grommet full theme={hpe}>
        <MainHeader />
        <MiddleBody />
        <TeamDetail/>
      </Grommet>
    );
}
  
export default Dashboard;

{/* <PrivateRoute path="/">
  <Dashboard />
</PrivateRoute> */}





