import React, { useContext, } from 'react';
import { Card, Box, Text, Heading, ResponsiveContext } from 'grommet';


const Ticker = () => {
    const size = useContext(ResponsiveContext);

    const allItems = [
        // {
        //     text: 'RDA tunnel has 0.05ms latency across region',
        //     color: "Green"
        // },
        // {
        //     text: 'XYZ canary is failing at Paris region',
        //     color: "red"
        // },
        // {
        //     text: '95% Canary are passing in aquila cluster',
        //     color: "Green"
        // },
        // {
        //     text: 'Downtime of 2 hours for CCS monitoring Infra',
        //     color: "orange"
        // }, {
        //     text: 'RDA tunnel has 0.05ms latency across region',
        //     color: "Green"
        // },
        // {
        //     text: 'XYZ canary is failing at Paris region',
        //     color: "red"
        // },
        // {
        //     text: '95% Canary are passing in aquila cluster',
        //     color: "Green"
        // },
        // {
        //     text: 'RDA tunnel has 0.05ms latency across region',
        //     color: "Green"
        // },
        // {
        //     text: 'XYZ canary is failing at Paris region',
        //     color: "red"
        // },
    ]

    return (
        <Card height="100%" pad="small" gap="medium" background="light-1" style={{maxHeight:"300px"}}>
            <Text color="text-strong" weight="bold">
                GLCP Continous Monitoring Events
            </Text>
            <Box flex overflow="auto"   gap='small'>
                <ul>
                {allItems.map((item) => (
                    <li>
                        <Text color={item.color} >{item.text}</Text>
                    </li>
                ))}
                </ul>
            </Box>
        </Card>
    );
};
export default Ticker;