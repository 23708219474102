export const canaryDetailsURL = "https://mv0shxaobi.execute-api.eu-west-3.amazonaws.com/dev"
export const authenticationURL = "https://ib83fjpic8.execute-api.us-west-1.amazonaws.com/dev"
export const aws_regions = {
    "us-east-1": "N. Virginia",
    "us-east-2": "Ohio",
    "us-west-1": "N. California",
    "us-west-2": "Oregon",
    "af-south-1": "Cape Town",
    "ap-east-1": "Hong Kong",
    "ap-south-1": "Mumbai",
    "ap-northeast-2": "Seoul",
    "ap-southeast-1": "Singapore",
    "ap-southeast-2": "Sydney",
    "ap-northeast-1": "Tokyo",
    "ca-central-1": "Central",
    "cn-north-1": "Beijing",
    "cn-northwest-1": "Ningxia",
    "eu-central-1": "Frankfurt",
    "eu-west-1": "Ireland",
    "eu-west-2": "London",
    "eu-west-3": "Paris",
    "eu-north-1": "Stockholm",
    "me-south-1": "Bahrain",
    "sa-east-1": "Sao Paulo"
}

export const ServiceName = {
    "AuditTrail": " Audit-Trail",
    "ADD": " Activate-ADD",
    "License": " Activate-SM",
    "Device": " Activate-ADI",
    "AppRoles": " AuthZ",
    "AuthNAuthZ": " AuthN",
    "AOP ": " Activate-AOP"
}