import axios from 'axios'
import { canaryDetailsURL } from '../Store/url.js';
export default {
    async getAllCanary(cluster) {
        const authorizationToken = process.env.REACT_APP_CONTINOUS_MONITORING_PHASE_1_API_KEY
        try {
            var response = await axios.get(
                `${canaryDetailsURL}/canaries`,
                {
                    params: {
                        "cluster": cluster
                    },
                    headers: {
                        "x-api-key": authorizationToken
                    }
                }
            );
        } catch (error) {
            console.log(error)
            return ""
        }
        return response.data;
    },

    async getArtifacts(s3Location) {
        const authorizationToken = process.env.REACT_APP_CONTINOUS_MONITORING_PHASE_1_API_KEY
        try {
            var response = await axios.get(
                `${canaryDetailsURL}/artifacts`,
                {
                    params: {
                        "artifacts": s3Location
                    },
                    headers: {
                        "x-api-key": authorizationToken
                    }
                }
            );
        } catch (error) {
            console.log(error)
            return ""
        }
        return response.data;
    },


    async getCloudwatchLogs(region , log_group, log_stream) {
        const authorizationToken = process.env.REACT_APP_CONTINOUS_MONITORING_PHASE_1_API_KEY
        try {
            var response = await axios.get(
                `${canaryDetailsURL}/cloudwatchlog`,
                {
                    params: {
                        "region": region,
                        "log_group_name":log_group,
                        "log_stream_name":log_stream
                    },
                    headers: {
                        "x-api-key": authorizationToken
                    }
                }
            );
        } catch (error) {
            console.log(error)
            return ""
        }
        return response.data;
    }
};

