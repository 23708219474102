import React, { useContext } from 'react';
import { Card, Text, Box, ResponsiveContext } from 'grommet';
import { Hpe } from 'grommet-icons';
import { useSelector } from "react-redux";


/**
 * MyComponent - It will give you the global status about cluster status.
*/
const GlobalStatus = () => {
    const size = useContext(ResponsiveContext);
    const clusterHealth = useSelector((state) => state.teamCanary.clusterHealth)

    return (
        <Card height="90%" direction='column' gap='xsmall' background="light-1" justify='center'>
            <Box alignSelf='center'>
                <Hpe color={clusterHealth=="Healthy"?"brand":"Red"} size='100px' />
            </Box >
            <Text level={2} color="text-strong" weight="bold" alignSelf="center" margin={{ top: '0px' }}>
                {clusterHealth}
            </Text>
        </Card>
    );
};
export default GlobalStatus;