import React, {useEffect}from 'react';
import { Box, CheckBox, Grid, Select, Text, WorldMap } from 'grommet';
import { useDispatch,useSelector } from "react-redux";

import { executiveViewUIActions } from '../../../Reducers/ExecutiveViewUI/executiveViewUISlice';

const placeProps = (name, color, showDrop) => ({
    name,
    color,
    ...(showDrop
        ? {
            content: (
                <Box pad={{ horizontal: 'small', vertical: 'xsmall' }}>
                    <Text>{name}</Text>
                </Box>
            ),
            dropProps: {
                align: { left: 'right' },
                background: { color, opacity: 'strong' },
                elevation: 'medium',
                margin: { left: 'small' },
                round: 'xsmall',
            },
        }
        : {}),
});


/**
 * PlacesComponent -This component draws worldmap and places showcasing region.
 */
export const Places = () => {

    const dispatch = useDispatch()
    const toggleCluster = useSelector((state) => state.executiveViewUI.executiveViewController.cluster)
    const [showDrops, setShowDrops] = React.useState(true);

    const options = ['Aquila', 'Pavo', 'Mira'];
    const [value, setValue] = React.useState('Aquila');
    const [places, setPlaces] = React.useState();

    const onSelectPlace = (place) => {
        console.log('Selected', place);
        setPlaces([{ color: 'graph-1', location: place }]);
    };

    const onClusterChange = (cluster) => {
        console.log(cluster.value)
        setValue(cluster)
        dispatch(executiveViewUIActions.setToggleCluster(cluster.value))
    }

    useEffect(() => {
        setValue(toggleCluster)
    }, [toggleCluster]);


    return (
        <Grid  justifyContent='center'>
            <Box height="medium"  >
                <Box
                    direction="row"
                    align="start"
                    gap="xxsmall"
                    justify='between'
                >
                    <CheckBox
                        label="Location"
                        checked={showDrops}
                        onChange={() => setShowDrops(showDrops=>!showDrops)}
                    />
                    <Box  >
                        {/* <Button onClick={() => setOpen(!open)} /> */}
                        <Select
                            id="select"
                            name="select"
                            placeholder={value}
                            options={options}
                            onChange={onClusterChange}
                        />
                    </Box>

                </Box>
                {/* onSelectPlace={onSelectPlace} if you want to choose region */}
                <WorldMap fill="vertical" color="graph-4"
                    places={[
                        {
                            location: [43.8566, 2.3522],
                            ...placeProps('Paris', 'graph-1', showDrops),
                        },
                        {
                            location: [1.3521, 103.8198],
                            ...placeProps('Singapore', 'graph-2', showDrops),
                        },
                        {
                            location: [44.8375, -120.8958],
                            ...placeProps('Northern California', 'graph-3', showDrops),
                        },
                        {
                            location: [19.0760, 72.8777],
                            ...placeProps('Mumbai', 'graph-0', showDrops),
                        },
                        {
                            location: [-23.5558, -46.6396],
                            ...placeProps('São Paulo', 'graph-1', showDrops),
                        },
                        {
                            location: [-33.8688, 151.2093],
                            ...placeProps('Sydney', 'graph-0', showDrops),
                        },
                        {
                            location: [35.4173, -82.9071],
                            ...placeProps('★ Ohio', '#F24C3D', showDrops),
                        },
                        {
                            location: [50.1109, 8.6821],
                            ...placeProps('Frankfurt', 'graph-0', showDrops),
                        },

                    ]}
                />
            </Box>
        </Grid>
    );
};

export default {
    title: 'Visualizations/WorldMap/Places',
    'Place': Places
};
