import React, { useState, useEffect } from 'react';
import { Box, Card, Tab, Tabs, Grid, Tip, Pagination, Text, Button } from 'grommet';
import { useSelector } from "react-redux";
import { aws_regions } from '../../Store/url';
import DialogBoxGUI from './DialogBox/dialogBoxGUI';
import DialogBoxAPI from './DialogBox/dialogBoxAPI';
import DialogBoxPhase2 from './DialogBox/dialogBoxPhase2';

const CardResult = ({ item, setDialogBox }) => {

    const coloumns = ['23%', '70%', '7%'];
    const regions = []
    const regionStatus = {}

    if ('phase2' in item && item.phase2) {

        for (const region in item.regions) {
            let failed = 0, passed = 0
            if (item.regions.hasOwnProperty(region)) {
                if (item.regions[region]["status"]) {
                    passed = 1
                } else {
                    failed = 1
                }
                const overallStatus = {
                    'failed': failed,
                    'passed': passed
                }
                regionStatus[region.trim()] = {
                    'overallStatus': overallStatus
                }
                regions.push(region.trim());
            }
        }

    }
    else {
        // this is api canary
        if (item.gui_canary === false) {
            let log_group = ""
            let log_stream = ""
            regions.push('eu-west-3')

            let failed = 0, passed = 0
            if (item.status_num === 0) {
                failed = 1
            } else {
                passed = 1
            }

            if ('artifacts' in item) {
                log_group = item.artifacts.log_group
                log_stream = item.artifacts.log_stream
            }

            const overallStatus = {
                'failed': failed,
                'passed': passed
            }
            regionStatus['eu-west-3'] = {
                'overallStatus': overallStatus,
                'artifacts': {
                    'log_group': log_group,
                    'log_stream': log_stream
                }
            }
        }

        // this is gui canary
        else {
            const s3LocationStatus = item.artifacts
            for (let region in item.artifacts) {
                regions.push(region)
                const overallStatus = {
                    'failed': 0,
                    'passed': 0
                }

                if (!('region' in regionStatus)) {
                    regionStatus[region] = {
                        'overallStatus': overallStatus,
                        'artifacts': []
                    }
                }

                for (let index in s3LocationStatus[region]) {
                    if (s3LocationStatus[region][index].status === false) {
                        overallStatus['failed'] += 1;
                    } else {
                        overallStatus['passed'] += 1;
                    }
                    regionStatus[region]['artifacts'].push(s3LocationStatus[region][index])
                }
            }
        }
    }

    return (
        <Grid
            columns={coloumns}
            gap="1%"
            margin="xsmall"
            justifyContent='center'
            pad="medium"
        >
            <Button tip={item.canaryname} truncate={true}  size='small'  label={item.canaryname}  alignSelf='center' style={{ backgroundColor: "#E8F9FD",textOverflow:"clip" }} />
            <Card
                direction='row'
                justify='start'
                gap='medium'
                pad='small'
                overflow={{ vertical: "hidden", horizontal: "auto" }}
            >
                {
                    regions.map((region) => {
                        let statusColor = '#F7D060'
                        if (regionStatus[region].overallStatus.failed === 0) {
                            statusColor = '#98D8AA'
                        }
                        if (regionStatus[region].overallStatus.passed === 0) {
                            statusColor = '#FF6464'
                        }
                        return (
                            <Button alignSelf='center'
                                style={{ background: statusColor, color: "whitesmoke", borderRadius: "5px" }}
                                hoverIndicator={{ background: "#99bcc4", elevation: true }}
                                onClick={setDialogBox.bind(this, region, regionStatus[region], item.team_name, item.canaryname, item.cluster, item.gui_canary, item.timestamp)}
                            >
                                <Text alignSelf='center' size='medium' truncate="tip" weight='bolder'>
                                    &nbsp;&nbsp;&nbsp;{aws_regions[region]}&nbsp;&nbsp;&nbsp;
                                </Text>
                            </Button>
                        )
                    })
                }
            </Card>
            <Box alignSelf='center'  >
                <Text alignSelf='center' size='medium' truncate="tip" weight='bolder' style={{ backgroundColor: item.status ? "#00D100" : "#FF0000", color: "whitesmoke", borderRadius: "10px" }} >&nbsp;{item.status ? "Passed" : "Failed"}&nbsp;</Text>
            </Box>
        </Grid>

    )
};


const ScrollableTabs = (props) => {

    const filterDetails = useSelector((state) => state.filterStorage.filterStorage)
    const teamDetails = useSelector((state) => state.teamCanary.teamsList)

    const teamList = []
    const passedTeamList = []
    const failedTeamList = []

    Object.entries(teamDetails).map(([key, value]) => (
        value.forEach(element => {
            if (element.status) {
                passedTeamList.push(element)
            } else {
                failedTeamList.push(element)
            }
            teamList.push(element)
        })
    ));

    const [currentData, setCurrentData] = useState(teamList.slice(0, 8));
    const [pageNumberDefault, setpageNumberDefault] = useState(1);
    const [tabIndex, seTabIndex] = useState(0);

    const handleChange = ({ page, startIndex, endIndex }) => {
        setpageNumberDefault(page)
        const nextData = teamList.slice(startIndex, endIndex);
        setCurrentData(nextData);
    };

    const handleChangePassed = ({ page, startIndex, endIndex }) => {
        // console.log('-----------------', passedTeamList, passedTeamList[props.team])
        if (props.team === '') {
            setpageNumberDefault(page)
            const nextData = passedTeamList.slice(startIndex, endIndex);
            setCurrentData(nextData);
        } else {
            setpageNumberDefault(page)
            const nextData = teamDetails[props.team].slice(startIndex, endIndex);
            setCurrentData(nextData);
        }
    };

    const handleChangeFailed = ({ page, startIndex, endIndex }) => {
        setpageNumberDefault(page)
        const nextData = failedTeamList.slice(startIndex, endIndex);
        setCurrentData(nextData);
    };

    const tabChanged = (tabIndex) => {
        seTabIndex(tabIndex)
        if (props.team === '') {
            if (tabIndex == 0) {
                setpageNumberDefault(1)
                setCurrentData(teamList.slice(0, 8))
            }
            if (tabIndex == 1) {
                setpageNumberDefault(1)
                setCurrentData(passedTeamList.slice(0, 8))
            }
            if (tabIndex == 2) {
                setpageNumberDefault(1)
                setCurrentData(failedTeamList.slice(0, 8))
            }
        } else {
            // console.log("------------", teamDetails[props.team])
            setpageNumberDefault(1)
            setCurrentData(teamDetails[props.team].slice(0, 8))
        }
    };


    useEffect(() => {
        if (props.team === '') {
            tabChanged(tabIndex)
        } else {
            setpageNumberDefault(1)
            setCurrentData(teamDetails[props.team].slice(0, 8))
        }
    }, [filterDetails, props.team, teamDetails])

    // console.log(teamList)

    const [openDialogBox, setOpenDialogBox] = React.useState(false);
    const [dialogBoxUniqueData, setDialogBoxUniqueData] = React.useState();
    const [canaryType, setCanaryType] = React.useState();

    const setDialogBox = (region, artifacts, team, canary, cluster, canaryType, timestamp) => {
        console.log(region, artifacts, team, timestamp, canary, canaryType)
        const data = {
            "team": team,
            "canary": canary,
            "cluster": cluster,
            "timestamp": timestamp,
            "region": region,
            "canaryType": canaryType,
            "artifactsStatus": artifacts
        }
        setDialogBoxUniqueData(data)
        setCanaryType(canaryType)
        setOpenDialogBox((openDialogBox) => !openDialogBox)
    }
    const closeDialogBox = () => {
        setOpenDialogBox((openDialogBox) => !openDialogBox)
    }

    return (
        <>{teamList.length !== 0 &&
            < Box fill elevation='medium'>
                <Tabs justify='center' fill alignControls='stretch' onActive={tabChanged}>

                    <Tab title="All">
                        <Box overflow={{ vertical: "visible" }} height="100%" pad="small" background="white" elevation="true" round="small" >
                            <Grid
                                rows="xxsmall"
                                gap="medium"
                                justify="evenly"
                                height="100%"
                            >
                                {
                                    props.team === '' &&
                                    <>
                                        {
                                            teamList.length / 8 >= 1 &&
                                            <Box align="center" direction="row" justify="center">
                                                <Pagination page={pageNumberDefault} numberItems={teamList.length} onChange={handleChange} step={8} />
                                            </Box>
                                        }

                                        {currentData.map((data) => (
                                            < CardResult item={data} key={data.canaryname + data.region} setDialogBox={setDialogBox} />
                                        ))}
                                    </>
                                }
                                {
                                    props.team in teamDetails &&
                                    <>
                                        {
                                            // This helps to remove pagination componenet if pages are less than 1
                                            teamDetails[props.team].length / 8 >= 1 &&
                                            <Box align="center" direction="row" justify="center">
                                                <Pagination page={pageNumberDefault} numberItems={teamDetails[props.team].length} onChange={handleChange} step={8} />
                                            </Box>
                                        }

                                        {currentData.map((data) => (
                                            < CardResult item={data} key={data.canaryname + data.region} setDialogBox={setDialogBox} />
                                        ))}
                                    </>
                                }
                            </Grid>
                        </Box>

                    </Tab>

                    <Tab title="Passed" align="center" >
                        <Box overflow={{ vertical: "auto" }} height="120%" pad="small" background="white" elevation="true" round="small">
                            <Grid rows="xxsmall" gap="medium" justify="evenly">
                                {
                                    props.team === '' &&
                                    <>
                                        {
                                            passedTeamList.length / 8 >= 1 &&
                                            <Box align="center" direction="row" justify="center">
                                                <Pagination page={pageNumberDefault} numberItems={passedTeamList.length} onChange={handleChangePassed} step={8} />
                                            </Box>
                                        }

                                        {currentData.map((data) => (
                                            < CardResult item={data} key={data.canaryname + data.region} setDialogBox={setDialogBox} />
                                        ))}
                                    </>
                                }
                                {
                                    props.team in teamDetails &&
                                    <>
                                        {
                                            // This helps to remove pagination componenet if pages are less than 1
                                            teamDetails[props.team].length / 8 >= 1 &&
                                            <Box align="center" direction="row" justify="center">
                                                <Pagination page={pageNumberDefault} numberItems={teamDetails[props.team].length} onChange={handleChangePassed} step={8} />
                                            </Box>
                                        }


                                        {currentData.map((data) => {
                                            if (data.status) {
                                                return (
                                                    < CardResult item={data} key={data.canaryname + data.region} setDialogBox={setDialogBox} />
                                                )
                                            }
                                        }
                                        )}
                                    </>
                                }
                            </Grid>
                        </Box>
                    </Tab>

                    <Tab title="Failed" align="center">
                        <Box overflow={{ vertical: "auto" }} height="120%" pad="small" background="white" elevation="true" round="small">
                            <Grid rows="xxsmall" gap="medium" justify="evenly">
                                {
                                    props.team === '' &&
                                    <>
                                        {
                                            failedTeamList.length / 8 >= 1 &&
                                            <Box align="center" direction="row" justify="center">
                                                <Pagination page={pageNumberDefault} numberItems={failedTeamList.length} onChange={handleChangeFailed} step={8} />
                                            </Box>
                                        }

                                        {currentData.map((data) => (
                                            < CardResult item={data} key={data.canaryname + data.region} setDialogBox={setDialogBox} />
                                        ))}
                                    </>
                                }
                                {
                                    props.team in teamDetails &&
                                    <>
                                        {
                                            // This helps to remove pagination componenet if pages are less than 1
                                            teamDetails[props.team].length / 8 >= 1 &&
                                            <Box align="center" direction="row" justify="center">
                                                <Pagination page={pageNumberDefault} numberItems={teamDetails[props.team].length} onChange={handleChangeFailed} step={8} />
                                            </Box>
                                        }
                                        {
                                            currentData.map((data) => {
                                                if (!data.status) {
                                                    return (
                                                        < CardResult item={data} key={data.canaryname + data.region} setDialogBox={setDialogBox} />
                                                    )
                                                }
                                            }
                                            )}
                                    </>
                                }
                            </Grid>
                        </Box>
                    </Tab>

                </Tabs>
            </Box >
        }
            {
                <>

                    {/* this is called when canaraytype is GUI */}
                    {openDialogBox && canaryType &&
                        <DialogBoxGUI closeDialogBox={closeDialogBox} data={dialogBoxUniqueData} />
                    }


                    {/* //this is called when canaraytype is API             */}
                    {openDialogBox && canaryType === false &&
                        <>
                            {console.log("This is the bold api canary")}
                            <DialogBoxAPI closeDialogBox={closeDialogBox} data={dialogBoxUniqueData} />
                        </>
                    }
                    {/* //this is called when canaraytype is undefined (phase2 )      */}
                    {openDialogBox && canaryType === undefined &&
                            <>
                                {console.log("This is the phase2 canary")}
                                <DialogBoxPhase2 closeDialogBox={closeDialogBox} data={dialogBoxUniqueData} />
                            </>
                    }
                </>
            }
        </>
    )
};

export const CanaryDetail = (props) => <ScrollableTabs team={props.team} />;

CanaryDetail.args = {
    full: true,
};