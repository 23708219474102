import axios from 'axios'
import { authenticationURL } from '../Store/url.js';
export default {
    async getHistoricalInfo(queryData) {

        // query data format look like this
        // const data = {
        //     "query": true,
        //     "phase1": false,
        //     "phase2": true,
        //     "team": "rdatunnel",
        //     "canary": "test_connectivity",
        //     "cluster": "aquila",
        //     "region": "us-west-1",
        //     "timestampStart": 1695426200,
        //     "timestampEnd": 1695686400
        // }
        console.log(queryData)
        
        const authorizationToken = process.env.REACT_APP_CONTINOUS_MONITORING_AUTHENTICATE_API_KEY
        try {
            var response = await axios.get(
                `${authenticationURL}/historical_info`,
                {
                    params: queryData,
                    headers: {
                        "x-api-key": authorizationToken
                    }
                }
            );
        } catch (error) {
            console.log(error)
            return ""
        }
        return response.data;
    }
};