import React, { useEffect, useState } from "react";
import { Box, Grid, Card, InfiniteScroll, Text, Spinner } from 'grommet';
import { useDispatch, useSelector } from "react-redux";

import { fetchAllTeamsPhase1, fetchAllTeamsPhase2 } from "../../Reducers/TeamCanary/teamCanaryActions";
import { CanaryDetail } from './canaryDetails'
import { ServiceName } from "../../Store/url";

/**
 * TeamDetailListComponent - This component will display team name.
 *
 * @param {Object} props - {
 *                         canaryType :{API: true/false, GUI: true/false}
 *                         phase: "Continous Monitoring Legacy/Continous Monitoring"
 *                         refresh : false.
 *                         }
 * @link Redux element  - > teamList , cluster
 * @returns {JSX.Element} The rendered component.
 */
const TeamDetailList = (props) => {
    const dispatch = useDispatch()
    const teamDetails = useSelector((state) => state.teamCanary.teamsList)
    const toggleCluster = useSelector((state) => state.executiveViewUI.executiveViewController.cluster)
    const networkIssue = useSelector((state) => state.teamCanary.networkIssue)
    const [selectedTeam, setselectedTeam] = useState('')

    // We are preparing all the team to display and there status
    const teamList = []
    for (const teamDetail in teamDetails) {
        let status = true
        if (teamDetails.hasOwnProperty(teamDetail)) {
            const canaryDetail = teamDetails[teamDetail];
            canaryDetail.forEach(element => {
                if (element.status === false) {
                    status = false
                }
            })
        }
        teamList.push({ team: teamDetail, status: status });
    }

    const onChangeSelectedTeam = (team) => {
        setselectedTeam(team)
    }


    useEffect(() => {
        const interval = setInterval(() => {
            //we need to search for team in phase 2 if toggle is on
            if (props.phase === 'Continous Monitoring') {
                if (selectedTeam === '') {
                    // console.log("called 60 sec phase 2", toggleCluster)
                    dispatch(fetchAllTeamsPhase2(toggleCluster))
                    setselectedTeam('')
                } else {
                    // console.log("called 60 sec with a team phase 2", toggleCluster)
                    dispatch(fetchAllTeamsPhase2(toggleCluster))
                    setselectedTeam(selectedTeam)
                }
            } else {
                if (selectedTeam === '') {
                    // console.log("called 60 sec", toggleCluster)
                    dispatch(fetchAllTeamsPhase1(toggleCluster, props.canaryType["API"], props.canaryType["GUI"]))
                    setselectedTeam('')
                } else {
                    // console.log("called 60 sec with a team", toggleCluster)
                    dispatch(fetchAllTeamsPhase1(toggleCluster, props.canaryType["API"], props.canaryType["GUI"]))
                    setselectedTeam(selectedTeam)
                }
            }
        }, 60000);
        return () => {
            clearInterval(interval);
        };
    }, [toggleCluster, selectedTeam, props]);


    useEffect(() => {
        if (props.phase === 'Continous Monitoring') {
            dispatch(fetchAllTeamsPhase2(toggleCluster))
        } else {
            dispatch(fetchAllTeamsPhase1(toggleCluster, props.canaryType["API"], props.canaryType["GUI"]))
        }
        setselectedTeam('')
    }, [toggleCluster, props.phase, props.canaryType])

    // To print the team name with there status
    const CardResult = ({ item }) => {
        return (
            <Card
                onClick={onChangeSelectedTeam.bind(this, item.team)}
                fill pad="medium"
                align="center" justify="center"
                background={{ color: !item.status ? "#FF0000" : "#577D86" }}
                hoverIndicator={{ background: "#99bcc4", elevation: true }}
                border={item.team === selectedTeam && { size: "medium" }}>
                {ServiceName[item.team] === undefined ? item.team : ServiceName[item.team]}
                {/* {item.team} */}
            </Card>
        )
    };



    return (
        <>

            <Grid
                rows={["small", "xlarge"]}
                pad="medium"
                gap="medium"
            >
                <Box overflow={{ vertical: "scroll" }} height="90%" pad="small" background="white" elevation="small" round="small">

                    {networkIssue && <Text size="large" alignSelf="center" >Sorry! Network Issue Found</Text>}
                    {networkIssue===false && teamList.length == 0 && <Text size="large" alignSelf="center" >No Services are available</Text>}

                    <Grid columns="small" rows="xxsmall" gap="medium" justify="center" pad="small">
                        <InfiniteScroll items={teamList} step={10}>
                            {((val) => (
                                <CardResult item={val} key={val.team} />
                            ))}
                        </InfiniteScroll>
                    </Grid>
                </Box>
                {/* This will helps to print  the list of canary*/}
                <CanaryDetail team={selectedTeam} />
            </Grid>
        </>
    )

}

export default TeamDetailList;