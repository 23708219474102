import { configureStore } from "@reduxjs/toolkit";

import teamCanarySlice from "../Reducers/TeamCanary/teamCanarySlice";
import executiveViewUISlice from "../Reducers/ExecutiveViewUI/executiveViewUISlice";
import filterStorageSlice from "../Reducers/FilterStorage/filterStorageSlice";
export default configureStore({
    reducer: {
        teamCanary : teamCanarySlice.reducer,
        executiveViewUI : executiveViewUISlice.reducer,
        filterStorage : filterStorageSlice.reducer
    }
})