import { redirect } from 'react-router-dom';

const checkTokenExpiration = () => {
    const storedToken = localStorage.getItem('authTokenCCSUI');
    if (storedToken) {
      const tokenCreationTime = parseInt(storedToken.split('_')[1]); // Assuming token is in format "token_unixTimestamp"
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    //   console.log(currentTime , tokenCreationTime)
      if (currentTime - tokenCreationTime > 21600) { // 6 hours in seconds
        // Token has expired, remove from local storage and state
        localStorage.removeItem('authTokenCCSUI');
        return false;
      }
      return true;
    }
    return false;
  };

export function getAuthToken() {
  
    if(checkTokenExpiration()===false)
    return ''
  const token = localStorage.getItem('authTokenCCSUI');

  return token;
}


export function setAuthToken(token) {
    localStorage.setItem('authTokenCCSUI',token);
    return true;
  }

export function tokenLoader() {
  return getAuthToken();
}

export function checkAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect('/login');
  }
  return null
}

export function checkAuthLoaderLogin() {
    const token = getAuthToken();
    if (token) {
      return redirect('/');
    }
    return null
  }