import { createSlice } from "@reduxjs/toolkit";

const initialization = {
    filterStorage: {
        cluster: "Aquila",
        phase: "Continous Monitoring",
        canaryType: { API: true, GUI: true }
    },
};

const filterStorageSlice = createSlice({
    name: "FileStorageSlice",
    initialState: initialization,
    reducers: {
        setPreviousFilterOption(State, Action) {
            State.filterStorage = Action.payload
        }
    },
});

export const filterStorageActions = filterStorageSlice.actions;
export default filterStorageSlice;
