import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { Box, Card, Text, Button, CheckBox, RadioButtonGroup, Sidebar } from 'grommet';
import { Close } from 'grommet-icons';
import { useSelector } from "react-redux";


const FilterSidebar = (props) => {
    const filterDetails = useSelector((state) => state.filterStorage.filterStorage)
    
    const [radioButtonCluster, setRadioButtonCluster] = useState(filterDetails.cluster);
    const [phaseSelection, setPhaseSelection] = useState(filterDetails.phase);
    const [checkBoxAPI, setCheckBoxAPI] = useState(filterDetails.canaryType["API"]);
    const [checkBoxGUI, setCheckBoxGUI] = useState(filterDetails.canaryType["GUI"]);
    const [checkBoxVisible, setCheckBoxVisible] = useState(filterDetails.phase==="Continous Monitoring"?false:true)

    
    const setclearFilter = () => {
        setRadioButtonCluster((cluster) => cluster = "Aquila")
        setPhaseSelection((phase) => phase = "Continous Monitoring")
        setCheckBoxAPI((checkBoxAPI) => checkBoxAPI = true)
        setCheckBoxGUI((checkBoxGUI) => checkBoxGUI = true)
        setCheckBoxVisible((checkBoxVisible) => checkBoxVisible = false)
    }

    const SidebarHeader = () => (
        <Box fill
            width={{ min: "110%" }}
            align="center"
            gap="small"
            direction="row"
            margin={{ bottom: 'xxsmall' }}
            justify="around"
            background={{ color: "light-1" }}
        >
            <Text size="large" weight="bolder">Filter</Text>
            <Button icon={<Close size="xxlarge" color="brand" />} onClick={() => props.openSidebar()} ></Button>
        </Box>
    );

    const FilterOptionView = () => (
        <div >

            <SidebarHeader />
            {/* for selecting the cluster */}
            <div style={{ paddingTop: "5%" }}>
                <Text margin={{ bottom: 'xsmall' }} weight="bold" >Cluster</Text>
                <Card fill width={{ min: "100%" }} border={{ border: "2 px" }} elevation="None" >
                    <RadioButtonGroup
                        name="Cluster"
                        options={['Aquila', 'Pavo', 'Mira']}
                        value={radioButtonCluster}
                        onChange={(event) => setRadioButtonCluster(event.target.value)}
                    />
                </Card>
            </div>

            {/* This is for selecting the phase */}
            <div style={{ paddingTop: "3%" }}>
                <Text margin={{ bottom: 'xsmall' }} weight="bold">Phase</Text>
                <Card fill width={{ min: "100%" }} border={{ border: "2 px" }} elevation="None" >
                    <RadioButtonGroup
                        name="Canary"
                        options={['Continous Monitoring Legacy', 'Continous Monitoring']}
                        value={phaseSelection}
                        onChange={(event) => {
                            // console.log(event["value"])
                            if (event["value"] === "Continous Monitoring Legacy") {
                                setCheckBoxVisible(true)
                            }
                            if (event["value"] === "Continous Monitoring") {
                                setCheckBoxVisible(false)
                            }

                            return setPhaseSelection(event.target.value)
                        }}
                    />
                </Card>
            </div>

            {/* This is for selecting the type of canary you want */}
            {checkBoxVisible && <div style={{ paddingTop: "3%" }}>
                <Text margin={{ bottom: 'xsmall' }} weight="bold">Canary</Text>
                <Card fill width={{ min: "100%" }} border={{ border: "2 px" }} elevation="None" >
                    <CheckBox
                        name="Canary"
                        checked={checkBoxGUI}
                        label="GUI Canary"
                        onChange={(event) => setCheckBoxGUI(event.target.checked)}
                    />
                    <CheckBox
                        name="Canary"
                        checked={checkBoxAPI}
                        label="API Canary"
                        onChange={(event) => setCheckBoxAPI(event.target.checked)}
                    />
                </Card>
            </div>}

            <Card direction="row" justify="between" margin={{ top: "medium" }} elevation="None">
                <Button
                    label="Submit"
                    onClick={() => {
                        const canaryType= {
                            'API': checkBoxAPI,
                            'GUI': checkBoxGUI
                        } 
                        props.selectedFilterOption(radioButtonCluster , phaseSelection , canaryType) 
                        props.openSidebar()    
                    }}
                    primary
                />
                <Button type="reset" label="Clear Filter" onClick={() => { setclearFilter() }} />
            </Card>

        </div>
    );

    return (
        <>
            {/* this is for opening a portal in main index.file */}
            {props.filterOption && ReactDOM.createPortal(

                <Card direction="row" height={{ min: '100%' }} background={{ opacity: 1 }}>
                    <Sidebar
                        // animation={
                        //     { type: "fadeIn" }
                        // }
                        width={{ min: "25%" }}
                        elevation="true"
                        style={{ overflow: "hidden", position: "absolute", right: 0 ,top:0 }}
                        responsive={true}
                        background="white"
                        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
                    >
                        <FilterOptionView />
                    </Sidebar>
                </Card>

                , document.getElementById('sidebar-filter'))}
        </>
    )

}

export default FilterSidebar;